import HeaderPage from "./HeaderPage";
import SectionPage from "./SectionPage";
import FooterPage from "./FooterPage";
import "../index.css";
import "../css/queries.css"

function Main() {
  return (
    <div>
      <HeaderPage></HeaderPage>
      <SectionPage></SectionPage>
      <FooterPage></FooterPage>
    </div>
  );
}

export default Main;