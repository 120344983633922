import React from "react";
// import { useEffect } from "react";
import "../css/section.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faMagnifyingGlass,
  faHandshakeSimple,
  faGears,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
//import image from "../images/section_image.png";
import bajajLogo from "../images/bajajelectricals-logo.png";
import deconstructLogo from "../images/deconstruct-logo.png";
import fableStreet from "../images/fablestreet-logo.png";
import foxtaleLogo from "../images/foxtale-logo.png";
import interfloraLogo from "../images/interflora-logo.png";
import mankindLogo from "../images/mankind-logo.png";
import xanaduLogo from "../images/xanadu-logo.png";
// import AOS from "aos";
// import "aos/dist/aos.css";

function Section() {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //     easing: "ease-in-out",
  //   });
  // }, []);

  // const cubeConfig = [
  //   {
  //     key: 1,
  //     cubeStyle: {
  //       "--size": "6rem",
  //       "--top": "0",
  //       "--frontColor": "#fff",
  //       "--sideColor": "#f2f8fc",
  //     },
  //     position: { transform: "rotateX(0) rotateY(0)", left: "0" },
  //   },
  //   {
  //     key: 2,
  //     cubeStyle: {
  //       "--size": "3rem",
  //       "--top": "0",
  //       "--frontColor": "#fff",
  //       "--sideColor": "#f2f8fc",
  //     },
  //     position: { transform: "rotateX(0) rotateY(0)", left: "0" },
  //   },
  //   {
  //     key: 3,
  //     cubeStyle: {
  //       "--size": "3rem",
  //       "--top": "0",
  //       "--frontColor": "#fff",
  //       "--sideColor": "#e4f1f9",
  //     },
  //     position: { left: "0", transform: "rotateX(0) rotateY(0)" },
  //   },
  //   {
  //     key: 4,
  //     cubeStyle: {
  //       "--size": "1.2rem",
  //       "--top": "0",
  //       "--frontColor": "#caeece",
  //       "--sideColor": "#b8e9be",
  //     },
  //     position: { left: "0", transform: "rotateX(0) rotateY(0)" },
  //   },
  //   {
  //     key: 5,
  //     cubeStyle: {
  //       "--size": "5rem",
  //       "--top": "0",
  //       "--frontColor": "#fff6e6",
  //       "--sideColor": "#ffedcc",
  //     },
  //     position: { left: "0", transform: "rotateX(0) rotateY(0)" },
  //   },
  // ];

  // const renderedCube = cubeConfig.map((cube) => {
  //   return (
  //     <div className="cube-container" key={cube.key}>
  //       <div className="cube" style={cube.position}>
  //         <div className="face front" style={cube.cubeStyle}></div>
  //         <div className="face back" style={cube.cubeStyle}></div>
  //         <div className="face top" style={cube.cubeStyle}></div>
  //         <div className="face right" style={cube.cubeStyle}></div>
  //         <div className="face left" style={cube.cubeStyle}></div>
  //         <div className="face bottom" style={cube.cubeStyle}></div>
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <div className="container">
      <div className="block">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* {renderedCube} */}
      <main>
        <section className="section-hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">Innovate Engage Thrive</h1>
            
          </div>
          <div className="hero-img-box">
            <div className="first-sub-heading">
              <span>The Next</span>
              <br></br>
              <span className="rb-text-blue">Big Step</span>
            </div>
            <p className="hero-description">
            Unlock the Power of AI for Your Marketing Strategy!! Here innovation meets marketing excellence. Our AI-powered strategies will revolutionize the way businesses engage with their audience, leveraging cutting-edge technology to drive results and maximize ROI
            </p>
            <a className="section-btn" href="#contact">
              Get Started
            </a>
            {/* <img className="section-img" src={image} alt="svg" /> */}
          </div>
        </section>

        <section className="section-brand">
          <div className="brand-logos">
            {/* <h2 className="brand-heading">Trsuted by top most companies</h2> */}
            <div className="logos-slide">
              <div className="logo">
                <img src={bajajLogo} alt="Bajaj Electricals Logo" className="logo1 all-logos"></img>
                <img src={deconstructLogo} alt="Deconstruct logo" className="logo2 all-logos"></img>
                <img src={mankindLogo} alt="Mankind Logo" className="logo6 all-logos"></img>
                <img src={fableStreet} alt="FableStreet Logo" className="logo3 all-logos"></img>
                <img src={xanaduLogo} alt="Mankind Logo" className="logo7 all-logos"></img>
                <img src={foxtaleLogo} alt="Foxtale Logo" className="logo4 all-logos"></img>
                <img src={interfloraLogo} alt="Interflora Logo" className="logo5 all-logos"></img>
              </div>
            </div>
            
            <div className="logos-slide">
              <div className="logo">
                <img src={bajajLogo} alt="Bajaj Electricals Logo" className="logo1 all-logos"></img>
                <img src={deconstructLogo} alt="Deconstruct logo" className="logo2 all-logos"></img>
                <img src={mankindLogo} alt="Mankind Logo" className="logo6 all-logos"></img>
                <img src={fableStreet} alt="FableStreet Logo" className="logo3 all-logos"></img>
                <img src={xanaduLogo} alt="Mankind Logo" className="logo7 all-logos"></img>
                <img src={foxtaleLogo} alt="Foxtale Logo" className="logo4 all-logos"></img>
                <img src={interfloraLogo} alt="Interflora Logo" className="logo5 all-logos"></img>
              </div>
            </div>
          </div>
        </section>

        <section className="section-about" id="about">
          <div className="section-backgroundMask">
            <div className="section-about-background"></div>
          </div>
          <div className="hero-about-box">
            <h3 className="about-caption">About Us</h3>
            <h1 className="sub-heading">We Are Firstshot</h1>
            <div className="about-description">
              <p>
                At the core of our mission is a profound dedication to revolutionising marketing strategies through the boundless potential of artificial intelligence. With a fusion of extensive industry experience and cutting-edge AI technology, our team is committed to crafting innovative solutions that propel businesses towards unparalleled success.
              </p>
              <p>
              Our mission remains steadfast: to empower businesses of all sizes to flourish in the digital era. Through our AI-powered marketing solutions, we aim to catalyze growth, elevate customer engagement, and deliver tangible, measurable results. We believe that by harnessing the transformative power of AI, businesses can not only thrive but also lead the way in shaping the future of marketing.
              </p>
            </div>
          </div>
        </section>

        <section className="section-services" id="services">
          <div className="section-services-backgroundMask">
            <div className="section-services-background"></div>
          </div>
          <div className="services-details">
            <div className="services-heading">
              <div className="heading">
                <h3 className="services-caption">What we offer</h3>
                <h2 className="services-title">
                  Customized solutions designed to elevate your business success
                </h2>
              </div>
            </div>
            <div className="offer-list">
              <div className="offer-item-nbr">
                <p className="step-number">01</p>
                <p className="heading-tertiary">
                  AI-Powered Campaign Optimization
                </p>
              </div>
              <div className="offer-item">
                <p className="service-desc">
                  Leverage our advanced algorithms to analyze data in real-time and optimize your marketing campaigns for maximum effectiveness. Say goodbye to guesswork and hello to data-driven decisions that drive results
                </p>
              </div>
              <div className="offer-item">
                <p className="service-desc">
                  Personalized Content Creation: Elevate your content strategy with personalized content that resonates with your audience on a deeper level. Our AI-driven content creation tools enable you to craft tailored messaging, imagery, and offers that drive engagement, foster brand loyalty, and inspire action
                </p>
              </div>
              <div className="offer-item-nbr">
                <p className="step-number">02</p>
                <p className="heading-tertiary">
                  Predictive Analytics
                </p>
              </div>   
              <div className="offer-item-nbr">
                <p className="step-number">03</p>
                <p className="heading-tertiary">
                  Audience Segmentation
                </p>
              </div>
              <div className="offer-item">
                <p className="service-desc">
                  Target specific audience segments with precision and accuracy. Our audience segmentation capabilities allow you to identify and reach the right people at the right time, ensuring that your message resonates with your intended audience and drives meaningful interactions
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-services" id="services">
          <div className="section-services-backgroundMask">
            <div className="section-services-background"></div>
          </div>
          <div className="services-details">
            <div className="services-heading">
              <div className="heading">
                <h3 className="services-caption">Services</h3>
                <h2 className="services-title">
                  Tailor-made strategies, hands-on campaign execution and in
                  depth reporting
                </h2>
              </div>
            </div>
            <div className="services-lists">
              <div className="service-item">
                <FontAwesomeIcon icon={faBullseye} className="icon" />
                <p className="service-heading">Target</p>
                <p className="service-desc">
                  We search the targeted customers and categories while
                  indicating preferred cost per engagement.
                </p>
              </div>
              <div className="service-item">
                <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                <p className="service-heading">Identify</p>
                <p className="service-desc">
                  Search brand aligned Influencers to promote your campaign
                  through our patented matching algorithm.
                </p>
              </div>
              <div className="service-item">
                <FontAwesomeIcon icon={faHandshakeSimple} className="icon" />
                <p className="service-heading">Talent Relation</p>
                <p className="service-desc">
                  Managing talent and contracting with them for the campaign is
                  going to be a non-hassle task.
                </p>
              </div>
              <div className="service-item">
                <FontAwesomeIcon icon={faGears} className="icon" />
                <p className="service-heading">Content Management</p>
                <p className="service-desc">
                  Mobilise Influencers to create optimised visual content that
                  amplifies your brand and generate online buzz.
                </p>
              </div>
              <div className="service-item">
                <FontAwesomeIcon icon={faChartLine} className="icon" />
                <p className="service-heading">Reporting & Analytics</p>
                <p className="service-desc">
                  Track your influencer marketing performance through our
                  dashboard and measure your Return on Investment(ROI).
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Section;
