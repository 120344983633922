import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Main from "./pages/Main";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Queries from "./pages/Queries";
import "./index.css";
import "./css/queries.css"

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/queries",
      element: <Queries />,
    }
  ])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
