import "../css/privacy-policy.css";
import logo from "../images/firstshot_logo.png";

function Queries() {
  return (
    <div>
      <header>
        <div className="privacy-header-content">
          <a href="/">
            <img className="privacy-logo" src={logo} alt="firstshot logo" />
          </a>
        </div>
      </header>
      <content>
        <div class="privacy-content">
            <p class="privacy-sub-header">For any queries, please reach out to us at <a href="mailto:nishtha@firstshot.io">nishtha@firstshot.io</a></p>
        </div>
      </content>
    </div>
  );
}

export default Queries;