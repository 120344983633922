import { useEffect } from "react";
import "../css/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/firstshot_logo.png";

function Header() {
  useEffect(() => {
    var navListContainer = document.querySelector(".nav-list");
    var list = navListContainer.getElementsByClassName("nav-list-link");
    for (var i = 0; i < list.length; i++) {
      list[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

    const sectionHeroEl = document.querySelector(".header-sticky");

    const obs = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        // console.log(ent);

        if (ent.isIntersecting === false) {
          document.body.classList.add("sticky");
        }

        if (ent.isIntersecting === true) {
          document.body.classList.remove("sticky");
        }
      },
      {
        // In the viewport
        root: null,
        threshold: 0,
        rootMargin: "-10px",
      }
    );
    obs.observe(sectionHeroEl);

    const btnNavEl = document.querySelector(".btn-mobile-nav");
    const headerEl = document.querySelector(".header");

    btnNavEl.addEventListener("click", function () {
      headerEl.classList.toggle("nav-open");
    });

    const allLinks = document.querySelectorAll("a:link");
    allLinks.forEach(function (link) {
      link.addEventListener("click", function (e) {
        if (link.classList.contains("nav-list-link"))
          headerEl.classList.toggle("nav-open");
      });
    });
  });

  return (
    <div id="home" className="container">
      <header className="header">
        <div className="header-content">
          <a href="#home" className="header-logo">
            <img className="logo" src={logo} alt="firstshot logo" />
          </a>
          <nav className="header-nav">
            <ul className="nav-list">
              <li>
                <a className="nav-list-link active" href="#home">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-list-link" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-list-link" href="#services">
                  Services
                </a>
              </li>
              {/* <li>
                <a className="nav-list-link" href="#blogs">
                  Blogs
                </a>
              </li> */}
              <li>
                <a className="nav-list-link header-btn" href="#contact">
                  Get Started
                </a>
              </li>
            </ul>
          </nav>
          <button className="btn-mobile-nav">
            <FontAwesomeIcon
              icon={faBars}
              className="icon-mobile-nav"
              name="menu-outline"
            />
            <FontAwesomeIcon
              icon={faClose}
              className="icon-mobile-nav"
              name="close-outline"
            />
          </button>
        </div>
      </header>
      <div className="header-sticky"></div>
    </div>
  );
}

export default Header;
