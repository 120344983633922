import { useRef, useState } from "react";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";

function Footer() {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);
  const [messageFailed, setMessageFailed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s44y7p9",
        "template_cl9mhdw",
        form.current,
        "WugkWeMK-MYUTRtda"
      )
      .then(
        (result) => {
          setMessageSent(true);
          setMessageFailed(false);
          form.current.reset();
          // console.log(result);
        },
        (error) => {
          setMessageSent(false);
          setMessageFailed(true);
          // console.log(error);
        }
      );
  };

  return (
    <div id="contact">
      <footer className="footer container">
        <div className="section-contact-backgroundMask">
          <div className="section-contact-background"></div>
        </div>
        <div className="footer-box">
          <h3 className="footer-caption">Contact Us</h3>
          <h1 className="footer-sub-heading">Book a Demo or reach out to us</h1>
          <div className="contact-content footer-container">
            <div className="content-primary">
              <h3 className="contact-heading">Send us a message</h3>
              {messageSent && (
                <p className="message-sent">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="footer-icon"
                  />
                  <span>Your message was sent, thank you!</span>
                </p>
              )}
              {messageFailed && (
                <p className="message-failed">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="footer-icon"
                  />
                  <span>Error Occured while sending the message.</span>
                </p>
              )}
              <form ref={form} onSubmit={handleSubmit}>
                <fieldset>
                  <div className="form-field">
                    <input
                      className="contact-field"
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      className="contact-field"
                      name="email"
                      type="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      className="contact-field contact-text-area"
                      name="message"
                      placeholder="Your Message"
                      rows={10}
                      cols={50}
                      required
                    ></textarea>
                  </div>
                  <div>
                    <button className="form-btn">Submit</button>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="content-secondary">
              <h3 className="contact-heading">Contact Info</h3>
              {/* <div>
                <h4 className="contact-info-subheading">Where to Find Us</h4>
                <p className="contact-info-detail">
                  691/B, Behind Hotel Chanda <br /> Civil Lines, Jhansi, Uttar
                  Pradesh
                </p>
              </div> */}
              <div>
                <h4 className="contact-info-subheading">Email Us At</h4>
                <a href="mailto:nishtha@firstshot.io" className="contact-info-detail">nishtha@firstshot.io</a>
              </div>
              <div>
                <h4 className="contact-info-subheading">Call Us At</h4>
                <a href="tel:+919519659650" className="contact-info-detail">+91 9519659650</a>
                {/* <br/>
                <a href="tel:+919670016164" className="contact-info-detail">+91 9670016164</a> */}
              </div>

              <div>
                <ul className="contact-social">
                  <li>
                    <a href="https://in.linkedin.com/company/firstshot-technologies-private-limited" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="footer-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/firstshottech" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="footer-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100092588993541" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="footer-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <br/>
                <br/>
                <p className="footer-legal-name">&copy; Firstshot Technologies (OPC) Private Limited</p>
                <br/>
                <p><a className="footer-legal-name" href="https://firstshot.io/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                <p><a className="footer-legal-name" href="https://firstshot.io/queries" target="_blank" rel="noopener noreferrer">Queries</a></p>
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
